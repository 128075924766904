// eslint-disable-next-line @cbhq/react-prefer-named-module-import
import React from 'react';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {
  appVersion,
  isClient,
  isLocalDevelopment,
  isProductionRelease,
  isTestEnvironment,
  ReleaseStage,
  releaseStage,
} from ':data-marketplace/utils/constants';
import { BUGSNAG_API_KEY } from ':data-marketplace/utils/environment';

const enabledReleaseStages = [
  ReleaseStage.production,
  ReleaseStage.staging,
  ReleaseStage.development,
];

const isBugsnagEnabled = Boolean(BUGSNAG_API_KEY) && enabledReleaseStages.includes(releaseStage);

const config = {
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  endpoints: {
    notify: 'https://exceptions.coinbase.com',
    sessions: 'https://sessions.coinbase.com',
  },
  releaseStage,
  enabledReleaseStages,
  appVersion,
};

// Switch off logging during local development or unit/e2e tests
if (isLocalDevelopment || isTestEnvironment) {
  Object.assign(config, { logger: null });
}

if (isBugsnagEnabled) {
  Bugsnag.start(config);
}

// can be used for filtering in bugsnag
type LogErrorData = {
  // what was the user doing when the error occurred?
  context?: string;
  // how severe is the error? defaults to 'warning' for handled errors, and 'error' for unhandled errors
  severity?: Event['severity'];
  // use metadata to create custom tabs on the error page
  metadata?: {
    name: string;
    data: Record<string, unknown>;
  }[];
};

function logError(error: string | Error, data?: LogErrorData) {
  if (!isBugsnagEnabled || !data) {
    return;
  }

  // Always log errors on the server side, regardless of the environment.
  // Do not log client errors on the production environment.
  if (!isClient || (isClient && !isProductionRelease)) {
    // eslint-disable-next-line
    console.error('[error]', error);
  }

  Bugsnag.notify(error, (event: Event) => {
    const { context, severity, metadata } = data;

    if (metadata) {
      metadata.map((tab) => event.addMetadata(tab.name, tab.data));
    }

    // eslint-disable-next-line no-param-reassign
    event.context = context;

    // eslint-disable-next-line no-param-reassign
    event.severity = severity ?? 'error';
  });
}

export { Bugsnag, isBugsnagEnabled, logError };
