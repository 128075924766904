// eslint-disable-next-line @cbhq/react-prefer-named-module-import
import React, { memo } from 'react';
import { IntlProvider } from 'react-intl';
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import useSSR from 'use-ssr';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { FeatureFlagProvider } from '@cbhq/cds-web/system';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';

import Error500 from ':data-marketplace/components/Error/Error500';
import { Bugsnag, isBugsnagEnabled } from ':data-marketplace/utils/bugsnag';

type Props = {
  // Normally we should use ReactNode but TS doesn't like it on this occasion
  children: JSX.Element;
};

type NoopWrapperProps = {
  children: JSX.Element;
};

export const NoopWrapper = memo(function NoopWrapper(props: NoopWrapperProps) {
  return props.children;
});

// Attempt to wrap the entire application in a React error boundary, if not possible have a noop
const ErrorBoundary = isBugsnagEnabled
  ? (Bugsnag.getPlugin('react')?.createErrorBoundary(React) as BugsnagErrorBoundary)
  : NoopWrapper;

// Add all providers that need access to browser methods like window etc. during mount
// This approach isn't great and if provider is altering html you will get a warning on console
// Most providers should be safe but some can have bugs
function BrowserSideProviders({ children }: Props) {
  const { isBrowser } = useSSR();

  if (isBrowser) {
    // Keeping this ready for a provider with fragments
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return children;
}

export function Providers({ children }: Props) {
  return (
    <ErrorBoundary FallbackComponent={Error500}>
      <IntlProvider locale={Intl.DateTimeFormat().resolvedOptions().locale} defaultLocale="en">
        <FeatureFlagProvider frontierButton>
          <ThemeProvider scale="small">
            {/* Note for devs: Make sure the newly added providers do not break server side rendering. Add them to Providers if needed */}
            <PortalProvider>
              <BrowserSideProviders>{children}</BrowserSideProviders>
            </PortalProvider>
          </ThemeProvider>
        </FeatureFlagProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
}
