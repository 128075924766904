// Initialize bugsnag as early as possible
// eslint-disable-next-line simple-import-sort/imports
import ':data-marketplace/utils/bugsnag';

// TODO(jtan): Fix "import '@cbhq/cds-web/globalStyles';" to actually import properly
import './global.css';
import '@cbhq/cds-fonts/fonts.css';
import '@cbhq/cds-icons/fonts/web/icon-font.css';
import '@cbhq/cds-web/styles/global';

import { memo } from 'react';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import {
  identify,
  init as initAnalytics,
  logPageView,
  PlatformName,
  setLocation,
  setPageview,
} from '@cbhq/client-analytics';

import Layout from ':data-marketplace/components/Layout/Layout';
import { Providers } from ':data-marketplace/components/Providers/Providers';
import {
  appVersion,
  CB_ANALYTICS_PROJECT_NAME,
  DATADOG_COOKIE_KEY,
  DEVICE_ID_COOKIE_KEY,
  isClient,
  isDevelopmentRelease,
  isLocalDevelopment,
  isTestEnvironment,
} from ':data-marketplace/utils/constants';
import { NEXT_PUBLIC_URL } from ':data-marketplace/utils/environment';
import { isAnalyticsEnabled, useClientSideAnalyticsPageView } from ':data-marketplace/utils/events';

type MyAppProps = AppProps & {
  deviceId: string;
  isDataDog: boolean;
};

if (isAnalyticsEnabled) {
  // Marketing team has asked to see analytics events on deployed dev environment.
  const showDebugLogging = isDevelopmentRelease;

  initAnalytics({
    isProd: !isLocalDevelopment && !isTestEnvironment,
    projectName: CB_ANALYTICS_PROJECT_NAME,
    showDebugLogging,
    version: appVersion,
    platform: isClient ? PlatformName.web : PlatformName.server,
  });

  // TODO: Remove once CCA fixes this https://jira.coinbase-corp.com/browse/MDA-306
  if (isClient) {
    setPageview({ blacklistRegex: [], isEnabled: true });
    setLocation({});

    // Log initial page view for the app
    // - callMarkNTBT: false - for FIRST page view
    // - https://github.cbhq.net/mono/repo/pull/60698
    logPageView({ callMarkNTBT: false });
  }
}

const MyApp = memo(function MyApp({ Component, pageProps, deviceId, isDataDog }: MyAppProps) {
  const router = useRouter();
  const currentUrl = `${NEXT_PUBLIC_URL}${router.asPath}`;

  if (isAnalyticsEnabled) {
    identify({
      deviceId,
      // We are opting out of analytics events (not metrics because I couldn't find a way) when we detect DataDog monitor instance
      isOptOut: isDataDog,
    });
  }

  useClientSideAnalyticsPageView();

  return (
    <>
      <Head>
        <meta property="og:url" content={currentUrl} />
        <meta property="twitter:url" content={currentUrl} />
      </Head>

      <Providers>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Providers>
    </>
  );
});

// @ts-expect-error: getInitialProps does not exist on MyApp inferred type
MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  const cookies = new Cookies(appContext?.ctx?.req?.headers.cookie);

  const deviceId = String(cookies.get(DEVICE_ID_COOKIE_KEY) ?? '');
  const isDataDog = Boolean(cookies.get(DATADOG_COOKIE_KEY));

  return { ...appProps, deviceId, isDataDog };
};

export default MyApp;
