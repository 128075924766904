export const DEFAULT_PAGE_SIZE = 25;
export const CB_ANALYTICS_PROJECT_NAME = 'data_marketplace';
export const DOCUMENTATION_URL = 'https://help.coinbase.com/en/data-marketplace';
export const RELATED_CONTENT_SIDEBAR_WIDTH = '300px';
export const MINIMUM_APPLICATION_WIDTH = 1400;
export const DEVICE_ID_COOKIE_KEY = 'coinbase_device_id';
export const DATADOG_COOKIE_KEY = 'datadog-synthetics-public-id';
export const PACKAGE_FORMAT_COLLAPSED_ROW_THRESHOLD = 5;

// Query Parameters
export const QUERY_PARAM_CATEGORY_ID = 'categoryFilter';
export const QUERY_PARAM_PACKAGE_ID = 'packageFilter';
export const QUERY_PARAM_PACKAGE_DATA = 'sampleDataTab';
export const QUERY_PARAM_PACKAGE_FORMAT_TABLE = 'formatTable';
export const QUERY_PARAM_ASSET_ID = 'assetFilter';
export const QUERY_PARAM_PRODUCT_SEARCH = 'searchFilter';
export const QUERY_PARAM_PRODUCT_PAGE = 'productsPage';

export {
  appVersion,
  isClient,
  isDevelopmentRelease,
  isLocalDevelopment,
  isProductionRelease,
  isStagingRelease,
  isTestEnvironment,
  isTestRelease,
  ReleaseStage,
  releaseStage,
} from './environment';
