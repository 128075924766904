import { memo } from 'react';
import Link from 'next/link';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { Box, HStack, Spacer } from '@cbhq/cds-web/layout';
import { TextDisplay3 } from '@cbhq/cds-web/typography';

import Header from ':data-marketplace/components/Header/Header';

// We won't show the error or url in production.
// They will be used for logging once datadog has been set up
const Error500 = memo(function Error500() {
  return (
    <>
      <Header title="Error" />

      <HStack flexGrow={0} background="primary" flexWrap="wrap-reverse" alignItems="center">
        <Box maxWidth="850px" flexDirection="column" alignItems="flex-start" spacing={7}>
          <TextDisplay3 as="h2" color="secondary" spacingBottom={3}>
            Something went wrong
          </TextDisplay3>

          <Link href="/" passHref legacyBehavior>
            <Button variant="secondary">Return to Data Marketplace</Button>
          </Link>
        </Box>

        <Spacer />

        <HeroSquare name="errorWeb500" />
      </HStack>
    </>
  );
});

export default Error500;
