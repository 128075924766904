import { useEffect } from 'react';
import memoize from 'lodash/memoize';
import { useRouter } from 'next/router';
import {
  ActionType,
  ComponentType,
  logEvent,
  logPageView,
  ValidEventData,
} from '@cbhq/client-analytics';

import {
  isLocalDevelopment,
  isTestEnvironment,
  ReleaseStage,
  releaseStage,
} from ':data-marketplace/utils/constants';

type EventData = ValidEventData & {
  envName: string;
};

// eslint-disable-next-line @cbhq/ts-no-enum
export enum EventUUID {
  navbar_contact = 'bee662d0-1f35-4993-9d08-4948c73f2029',
  sidebar_documentation = 'acd42853-bd54-4608-8b6c-d2fbc20b800b',
  suggestion_contact = 'c7027fae-2531-4011-8583-89a31c931ace',
  suggestion = 'c44f5f78-5716-4845-af34-f59d0dc10dcb',
  products_results = '643525a4-2acd-4b2d-82dc-5ee30a463b1e',
  products_search = '3ebdf916-a7a5-484d-aa31-eaa908b93179',
  products_category_filter = '5eac7a86-d79e-4d23-83c9-0884ad5ac98c',
  products_package_filter = 'e1e1d601-572b-4133-984c-15149aeeed8c',
  products_asset_filter = '50b7185c-ab3e-4f23-b1ef-1a0226914232',
  products_category_change = 'ef588f93-74cc-4d16-b26c-b1929ce59409',
  products_package_change = 'effbd4d0-c7a2-4f8e-a874-3453b1a080b6',
  contact_form_submit = 'ee53aac5-3ef7-4a0a-a5c5-b263f7b938a5',
}

const enabledReleaseStages = [
  ReleaseStage.production,
  ReleaseStage.staging,
  ReleaseStage.development,
];

// allows for one line @maui/utils/eventing importing throughout app
export const EventActionType = ActionType;
export const EventComponentType = ComponentType;

// We only want to initialise Analytics on deployed environments
export const isAnalyticsEnabled =
  !isLocalDevelopment && !isTestEnvironment && enabledReleaseStages.includes(releaseStage);

// Reverse mapping of the above to get enum names easily
const EVENT_UUID_NAMES = new Map<string, string>(
  Object.entries(EventUUID).map(([key, value]: [string, string]) => [value, key]),
);

export function sendEvent(
  eventUUID: EventUUID,
  actionType: ActionType,
  componentType: ComponentType,
  optionalParams: object = {},
) {
  if (!isAnalyticsEnabled) {
    return;
  }

  const eventProperties: EventData = {
    action: actionType,
    componentType,
    loggingId: eventUUID.toString(),
    envName: releaseStage,
    ...optionalParams,
  };

  const eventName = EVENT_UUID_NAMES.get(eventUUID) ?? '';

  // send to CB analytics service
  logEvent(eventName, eventProperties);
}

// I decided to create a double function so I can log events without lots of useCallback boilerplate
// I created a P/PS on #ask-unified-client-analytics to make this a part of their library
// https://docs.google.com/document/d/1fCehQZ78cDK26J6u95LV6tOPc_3MxBvAoPMuZNPszzg
// If they find a fault with the proposal then I will remove this function
// Both functions are memoized with lodash's util to ensure they don't cause excessive re-renders
export const sendEventCallback = memoize(
  (
    eventUUID: EventUUID,
    actionType: ActionType,
    componentType: ComponentType,
    optionalParams: object = {},
  ) => {
    // This callback will be called by the element's own callback: eg: <Button onClick={sendEvent(...)}>
    const callback = memoize(() => sendEvent(eventUUID, actionType, componentType, optionalParams));

    return callback;
  },
);

// Taken from https://github.cbhq.net/frontend/maui/blob/42549e868fcfb0d805d72d0ee91ac0b2fb702dac/maui-frontend/utils/analytics/initialize.ts#L80
// TODO: Remove once CCA fixes this https://jira.coinbase-corp.com/browse/MDA-306
export function useClientSideAnalyticsPageView() {
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = () => {
      // NOTE: checking for isReady fixes a bug where this fires on initial page load
      // - https://github.com/vercel/next.js/issues/11639
      if (isAnalyticsEnabled && router.isReady) {
        logPageView();
      }
    };

    // Utilize changeStart as opposed to changeComplete
    // since the user will "experience" the page before
    // it fully completes loading
    router.events.on('routeChangeStart', onRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [router]);
}
