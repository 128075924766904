import { memo } from 'react';
import Link from 'next/link';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { Box } from '@cbhq/cds-web/layout';
import { NavigationBar, NavigationTitle } from '@cbhq/cds-web/navigation';

import {
  EventActionType,
  EventComponentType,
  EventUUID,
  sendEventCallback,
} from ':data-marketplace/utils/events';

export type Props = {
  title: string;
  previousPath?: string;
  variant?: 'contact';
};

const Header = memo(function Header({ title, previousPath, variant }: Props) {
  return (
    <NavigationBar
      start={
        previousPath ? (
          <Box spacingStart={2}>
            <Link href={previousPath} passHref legacyBehavior>
              <IconButton accessibilityLabel="Back" compact name="arrowLeft" variant="secondary" />
            </Link>
          </Box>
        ) : undefined
      }
      end={
        <Box spacingEnd={2} height="100%" alignItems="center">
          <Link href="/contact" passHref legacyBehavior>
            <Button
              compact
              disabled={variant === 'contact'}
              endIcon="arrowRight"
              // @ts-expect-error: Next.js Link only passes onClick events to children but CDS Button doesn't have an onClick on types
              onClick={sendEventCallback(
                EventUUID.navbar_contact,
                EventActionType.click,
                EventComponentType.link,
              )}
            >
              Contact sales
            </Button>
          </Link>
        </Box>
      }
    >
      <NavigationTitle>{title}</NavigationTitle>
    </NavigationBar>
  );
});

export default Header;
