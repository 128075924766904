import { memo } from 'react';
import Link from 'next/link';
import { LogoMark } from '@cbhq/cds-web/icons';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Sidebar, SidebarItem } from '@cbhq/cds-web/navigation';
import { Link as CDSLink } from '@cbhq/cds-web/typography';

import { DOCUMENTATION_URL, MINIMUM_APPLICATION_WIDTH } from ':data-marketplace/utils/constants';
import {
  EventActionType,
  EventComponentType,
  EventUUID,
  sendEventCallback,
} from ':data-marketplace/utils/events';
import { HorizontalOverflow } from ':data-marketplace/utils/hacks';

const Layout = memo(function Layout({ children }) {
  return (
    <HStack
      id="app"
      minWidth={MINIMUM_APPLICATION_WIDTH}
      dangerouslySetClassName={HorizontalOverflow}
    >
      <VStack>
        <Sidebar
          autoCollapse
          logo={
            <Link href="/" legacyBehavior>
              <a>
                <LogoMark />
              </a>
            </Link>
          }
        >
          <Link href="/" passHref>
            <CDSLink>
              <SidebarItem title="Data&nbsp;Marketplace" icon="dashboard" active />
            </CDSLink>
          </Link>

          <Link href={DOCUMENTATION_URL} passHref>
            <CDSLink
              target="_blank"
              rel="noopener noreferrer"
              onClick={sendEventCallback(
                EventUUID.sidebar_documentation,
                EventActionType.click,
                EventComponentType.link,
              )}
            >
              <SidebarItem title="Documentation" icon="documentation" active={false} />
            </CDSLink>
          </Link>
        </Sidebar>
      </VStack>

      <VStack flexGrow={1} overflow="hidden">
        {children}
      </VStack>
    </HStack>
  );
});

export default Layout;
