/* eslint-disable no-console */
// We will log a lot on this file

// Put all process.env.XXX usages on this file,
// Make sure to add a console error
//    Make sure to use isClient for clientside/serverside logging
// Make sure to not log sensitive information
// Populate all of our .env.XXX files accordingly
// If a value is not in our .env files then save it to your local zshrc/bashrc for development
// or provide it on codeflow on deployed environments

// eslint-disable-next-line @cbhq/ts-no-enum
export enum NodeEnv {
  production = 'production',
  development = 'development',
  test = 'test',
}

// eslint-disable-next-line @cbhq/ts-no-enum
export enum ReleaseStage {
  production = 'production',
  staging = 'staging',
  development = 'development',
  test = 'test',
}

export const isClient = typeof window !== 'undefined';

// Node Environments
const nodeEnv = process.env.NODE_ENV as NodeEnv;
const isDevelopmentNodeEnv = nodeEnv === NodeEnv.development;
const isTestNodeEnv = nodeEnv === NodeEnv.test;

// Release Stages
export const releaseStage = process.env.NEXT_PUBLIC_RELEASE_STAGE as ReleaseStage;
export const isProductionRelease = releaseStage === ReleaseStage.production;
export const isStagingRelease = releaseStage === ReleaseStage.staging;
export const isDevelopmentRelease = releaseStage === ReleaseStage.development;
export const isTestRelease = releaseStage === ReleaseStage.test;

// Environments
export const isLocalDevelopment = isDevelopmentNodeEnv;
export const isTestEnvironment = isTestNodeEnv || isTestRelease;

// Environment Variables
export const MULE_AEM_PUBLISHER_URL = process.env.MULE_AEM_PUBLISHER_URL ?? '';
export const MULE_AEM_PUBLISHER_BASIC_TOKEN = process.env.MULE_AEM_PUBLISHER_BASIC_TOKEN ?? '';
export const LEAD_SOURCE = process.env.LEAD_SOURCE ?? '';
export const PRODUCT_SERVICE = process.env.PRODUCT_SERVICE ?? '';
export const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '';
export const appVersion = process.env.NEXT_PUBLIC_APP_VERSION;
export const RECAPTCHA_SECRET_KEY = process.env.RECAPTCHA_SECRET_KEY ?? '';
export const RECAPTCHA_SITE_VERIFY_URL = process.env.RECAPTCHA_SITE_VERIFY_URL ?? '';
export const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';
export const NEXT_PUBLIC_URL = process.env.NEXT_PUBLIC_URL ?? '';
export const CX_LEADS_API_URL = process.env.CX_LEADS_API_URL ?? '';

// Error Logging
if (!isClient && !MULE_AEM_PUBLISHER_URL) {
  console.error(`MULE_AEM_PUBLISHER_URL is invalid: "${MULE_AEM_PUBLISHER_URL}"`);
}

if (!isClient && !MULE_AEM_PUBLISHER_BASIC_TOKEN) {
  // Do not log the token itself to the console
  console.error(`MULE_AEM_PUBLISHER_BASIC_TOKEN is invalid`);
}

if (!isClient && !LEAD_SOURCE) {
  console.error(`LEAD_SOURCE is invalid: "${LEAD_SOURCE}"`);
}

if (!isClient && !PRODUCT_SERVICE) {
  console.error(`PRODUCT_SERVICE is invalid: "${PRODUCT_SERVICE}"`);
}

if (Object.values(ReleaseStage).includes(releaseStage) && !BUGSNAG_API_KEY) {
  console.error(`NEXT_PUBLIC_BUGSNAG_API_KEY is invalid: "${BUGSNAG_API_KEY}"`);
}

if (!appVersion) {
  console.error(`NEXT_PUBLIC_APP_VERSION is invalid: "${appVersion}"`);
}

if (!isClient && !RECAPTCHA_SECRET_KEY) {
  console.error(`RECAPTCHA_SECRET_KEY is invalid`);
}

if (!isClient && !RECAPTCHA_SITE_VERIFY_URL) {
  console.error(`RECAPTCHA_SITE_VERIFY_URL is invalid: "${RECAPTCHA_SITE_VERIFY_URL}"`);
}

if (!NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
  console.error(
    `NEXT_PUBLIC_NEXT_PUBLIC_RECAPTCHA_SITE_KEY is invalid: "${NEXT_PUBLIC_RECAPTCHA_SITE_KEY}"`,
  );
}

if (!NEXT_PUBLIC_URL) {
  console.error(`NEXT_PUBLIC_NEXT_PUBLIC_URL is invalid: "${NEXT_PUBLIC_URL}"`);
}

if (!CX_LEADS_API_URL) {
  console.error(`CX_LEADS_API_URL is invalid: "${CX_LEADS_API_URL}"`);
}
